
/*======== @重置样式 =======*/
.feat-reset{
  *{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
  }
  table{border-collapse: collapse;}
  button,input[type="button"], input[type="submit"] {
    cursor: pointer;
  }
  i{font-style:normal;}

  :focus { outline: 0;}
  ul,ol{list-style:none;}
  a{text-decoration:none;}
  ::-webkit-input-placeholder{font-size: 12px;}

  //IE 选择框右侧下拉/输入框X/密码框眼睛
  select::-ms-expand { display: none; border: none; }
  input::-ms-clear { display: none;}
  input::-ms-reveal { display: none;}

  //chrome type=number移除箭头
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
    margin: 0;
  }

  //移动端-Ios点击后背景框
  *,*:focus{
    -webkit-tap-highlight-color:rgba(0,0,0,0);
  }
}






/*======== @基本布局 =======*/
//CSS容器样式
.fl {float: left;}
.fr {float: right;}
.hide {display: none !important;}
.show {display: block !important;}

/*-------字体文本样式--------*/

.tl {text-align: left;}
.tc {text-align: center;}
.tr {text-align: right;}
.fw{font-weight: bold;}

//清除浮动
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.clearfix {zoom:1;}






/*======== @flex布局 =======*/
[class*=fx-row],[class*=fx-col]{
  display: flex;
  flex-wrap: wrap;
}
[class*=fx-row]{ flex-direction: row; align-items: center;}
[class*=fx-col]{ flex-direction: column; }

.fx-align-t {align-items: flex-start;}
.fx-align-c {align-items: center;}
.fx-align-b {align-items: flex-end;}

.fx-row-c{justify-content: center;}
.fx-row-a{justify-content: space-around;}
.fx-row-b{justify-content: space-between;}
.fx-row-s{justify-content:flex-start;}
.fx-row-e{justify-content:flex-end;}
/*----------主轴竖向排列----------*/
.fx-col-c{justify-content: center;}
.fx-col-a{justify-content: space-around;}
.fx-col-b{justify-content: space-between;}
.fx-col-s{justify-content:flex-start;}
.fx-col-e{justify-content: flex-end;}
/*-----------项目属性----------*/
.fx-sf-c{align-self :center;}
.fx-sf-s{align-self :flex-start;}
.fx-sf-e{align-self :flex-end;}
.fx-sf-b{align-self :baseline;}
.fx-sf-se{align-self: stretch;}
.fx-g{flex-grow: 1;}




/*======== @栅格布局 =======*/
.feat-grid{

  //栅格行
  .row {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    zoom: 1;
    &:after {
      clear: both;
      content: "";
      display: block;
      height: 0;
      visibility: hidden;
    }
    //内部列
    &>[class*=col-] {
      float: left;
      box-sizing: border-box;
      /*flex: 0 0 auto;(是否嵌套)*/
    }
  }
  //默认是否增加间距
  .gutter{
    .row{
      margin: 0 -10px;
      &>[class*=col-] {
        padding:0 10px;
      }
    }
  }


  /*----------百分布局----------*/
  @media (min-width: 0px) {

    .col-xs-24 {width: 100%;}
    .col-xs-offset-24 {margin-left:100%;}

    .col-xs-23 {width: 95.83333333%;}
    .col-xs-offset-23 {margin-left:95.83333333%;}

    .col-xs-22 {width: 91.66666667%;}
    .col-xs-offset-22 {margin-left:91.66666667%;}

    .col-xs-21 {width: 87.5%;}
    .col-xs-offset-21 {margin-left:87.5%;}

    .col-xs-20 {width: 83.33333333%;}
    .col-xs-offset-20 {margin-left:83.33333333%;}

    .col-xs-19 {width: 79.16666667%;}
    .col-xs-offset-19 {margin-left:79.16666667%;}

    .col-xs-18 {width: 75%;}
    .col-xs-offset-18 {margin-left:75%;}

    .col-xs-17 {width: 70.83333333%;}
    .col-xs-offset-17 {margin-left:70.83333333%;}

    .col-xs-16 {width: 66.66666667%;}
    .col-xs-offset-16 {margin-left:66.66666667%;}

    .col-xs-15 {width: 62.5%;}
    .col-xs-offset-15 {margin-left:62.5%;}

    .col-xs-14 {width: 58.33333333%;}
    .col-xs-offset-14 {margin-left:58.33333333%;}

    .col-xs-13 {width: 54.16666667%;}
    .col-xs-offset-13 {margin-left:54.16666667%;}

    .col-xs-12 {width: 50%;}
    .col-xs-offset-12 {margin-left:50%;}

    .col-xs-11 {width: 45.83333333%;}
    .col-xs-offset-11 {margin-left:45.83333333%;}

    .col-xs-10 {width: 41.66666667%}
    .col-xs-offset-10 {margin-left:41.66666667%;}

    .col-xs-9 {width: 37.5%;}
    .col-xs-offset-9 {margin-left:37.5%;}

    .col-xs-8 {width: 33.33333333%;}
    .col-xs-offset-8 {margin-left:33.33333333%;}

    .col-xs-7 {width: 29.16666667%;}
    .col-xs-offset-7 {margin-left:29.16666667%;}

    .col-xs-6 {width: 25%;}
    .col-xs-offset-6 {margin-left:25%;}

    .col-xs-5 {width: 20.83333333%;}
    .col-xs-offset-5 {margin-left:20.83333333%;}

    .col-xs-4 {width: 16.66666667%;}
    .col-xs-offset-4 {margin-left:16.66666667%;}

    .col-xs-3 {width: 12.5%}
    .col-xs-offset-3 {margin-left:12.5%}

    .col-xs-2 {width: 8.33333333%;}
    .col-xs-offset-2 {margin-left: 8.33333333%;}

    .col-xs-1 {width: 4.16666667%;}
    .col-xs-offset-1 {margin-left: 4.16666667%;}
  }

  @media (min-width: 768px) {

    .col-sm-24 {width: 100%;}
    .col-sm-offset-24 {margin-left:100%;}

    .col-sm-23 {width: 95.83333333%;}
    .col-sm-offset-23 {margin-left:95.83333333%;}

    .col-sm-22 {width: 91.66666667%;}
    .col-sm-offset-22 {margin-left:91.66666667%;}

    .col-sm-21 {width: 87.5%;}
    .col-sm-offset-21 {margin-left:87.5%;}

    .col-sm-20 {width: 83.33333333%;}
    .col-sm-offset-20 {margin-left:83.33333333%;}

    .col-sm-19 {width: 79.16666667%;}
    .col-sm-offset-19 {margin-left:79.16666667%;}

    .col-sm-18 {width: 75%;}
    .col-sm-offset-18 {margin-left:75%;}

    .col-sm-17 {width: 70.83333333%;}
    .col-sm-offset-17 {margin-left:70.83333333%;}

    .col-sm-16 {width: 66.66666667%;}
    .col-sm-offset-16 {margin-left:66.66666667%;}

    .col-sm-15 {width: 62.5%;}
    .col-sm-offset-15 {margin-left:62.5%;}

    .col-sm-14 {width: 58.33333333%;}
    .col-sm-offset-14 {margin-left:58.33333333%;}

    .col-sm-13 {width: 54.16666667%;}
    .col-sm-offset-13 {margin-left:54.16666667%;}

    .col-sm-12 {width: 50%;}
    .col-sm-offset-12 {margin-left:50%;}

    .col-sm-11 {width: 45.83333333%;}
    .col-sm-offset-11 {margin-left:45.83333333%;}

    .col-sm-10 {width: 41.66666667%}
    .col-sm-offset-10 {margin-left:41.66666667%;}

    .col-sm-9 {width: 37.5%;}
    .col-sm-offset-9 {margin-left:37.5%;}

    .col-sm-8 {width: 33.33333333%;}
    .col-sm-offset-8 {margin-left:33.33333333%;}

    .col-sm-7 {width: 29.16666667%;}
    .col-sm-offset-7 {margin-left:29.16666667%;}

    .col-sm-6 {width: 25%;}
    .col-sm-offset-6 {margin-left:25%;}

    .col-sm-5 {width: 20.83333333%;}
    .col-sm-offset-5 {margin-left:20.83333333%;}

    .col-sm-4 {width: 16.66666667%;}
    .col-sm-offset-4 {margin-left:16.66666667%;}

    .col-sm-3 {width: 12.5%}
    .col-sm-offset-3 {margin-left:12.5%}

    .col-sm-2 {width: 8.33333333%;}
    .col-sm-offset-2 {margin-left: 8.33333333%;}

    .col-sm-1 {width: 4.16666667%;}
    .col-sm-offset-1 {margin-left: 4.16666667%;}
  }

  //pc小分辨率
  @media (min-width: 992px) {

    .col-md-24 {width: 100%;}
    .col-md-offset-24 {margin-left:100%;}

    .col-md-23 {width: 95.83333333%;}
    .col-md-offset-23 {margin-left:95.83333333%;}

    .col-md-22 {width: 91.66666667%;}
    .col-md-offset-22 {margin-left:91.66666667%;}

    .col-md-21 {width: 87.5%;}
    .col-md-offset-21 {margin-left:87.5%;}

    .col-md-20 {width: 83.33333333%;}
    .col-md-offset-20 {margin-left:83.33333333%;}

    .col-md-19 {width: 79.16666667%;}
    .col-md-offset-19 {margin-left:79.16666667%;}

    .col-md-18 {width: 75%;}
    .col-md-offset-18 {margin-left:75%;}

    .col-md-17 {width: 70.83333333%;}
    .col-md-offset-17 {margin-left:70.83333333%;}

    .col-md-16 {width: 66.66666667%;}
    .col-md-offset-16 {margin-left:66.66666667%;}

    .col-md-15 {width: 62.5%;}
    .col-md-offset-15 {margin-left:62.5%;}

    .col-md-14 {width: 58.33333333%;}
    .col-md-offset-14 {margin-left:58.33333333%;}

    .col-md-13 {width: 54.16666667%;}
    .col-md-offset-13 {margin-left:54.16666667%;}

    .col-md-12 {width: 50%;}
    .col-md-offset-12 {margin-left:50%;}

    .col-md-11 {width: 45.83333333%;}
    .col-md-offset-11 {margin-left:45.83333333%;}

    .col-md-10 {width: 41.66666667%}
    .col-md-offset-10 {margin-left:41.66666667%;}

    .col-md-9 {width: 37.5%;}
    .col-md-offset-9 {margin-left:37.5%;}

    .col-md-8 {width: 33.33333333%;}
    .col-md-offset-8 {margin-left:33.33333333%;}

    .col-md-7 {width: 29.16666667%;}
    .col-md-offset-7 {margin-left:29.16666667%;}

    .col-md-6 {width: 25%;}
    .col-md-offset-6 {margin-left:25%;}

    .col-md-5 {width: 20.83333333%;}
    .col-md-offset-5 {margin-left:20.83333333%;}

    .col-md-4 {width: 16.66666667%;}
    .col-md-offset-4 {margin-left:16.66666667%;}

    .col-md-3 {width: 12.5%}
    .col-md-offset-3 {margin-left:12.5%}

    .col-md-2 {width: 8.33333333%;}
    .col-md-offset-2 {margin-left: 8.33333333%;}

    .col-md-1 {width: 4.16666667%;}
    .col-md-offset-1 {margin-left: 4.16666667%;}
  }

}


.mt3 {
  margin-top: 3px;
}

.mt5 {
  margin-top: 5px;
}

.mt7 {
  margin-top: 7px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mr1 {
  margin-right: 1px;
}

.mr3 {
  margin-right: 3px;
}

.mr5 {
  margin-right: 5px;
}

.mr7 {
  margin-right: 7px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.ml1 {
  margin-left: 1px;
}

.ml3 {
  margin-left: 3px;
}

.ml5 {
  margin-left: 5px;
}

.ml7 {
  margin-left: 7px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.pd3 {
  padding: 3px;
}

.pd5 {
  padding: 5px;
}

.pd7 {
  padding: 7px;
}

.pd10 {
  padding: 10px;
}

.pd15 {
  padding: 15px;
}

.pd20 {
  padding: 20px;
}

.pd30 {
  padding: 30px;
}

.pdt3 {
  padding-top: 3px;
}

.pdt5 {
  padding-top: 5px;
}

.pdt7 {
  padding-top: 7px;
}

.pdt10 {
  padding-top: 10px;
}

.pdt15 {
  padding-top: 15px;
}

.pdt20 {
  padding-top: 20px;
}

.pdt30 {
  padding-top: 30px;
}

.pdb3 {
  padding-bottom: 3px;
}

.pdb5 {
  padding-bottom: 5px;
}

.pdb7 {
  padding-bottom: 7px;
}

.pdb10 {
  padding-bottom: 10px;
}

.pdb15 {
  padding-bottom: 15px;
}

.pdb20 {
  padding-bottom: 20px;
}

.pdb30 {
  padding-bottom: 30px;
}

.pdl3 {
  padding-left: 3px;
}

.pdl5 {
  padding-left: 5px;
}

.pdl7 {
  padding-left: 7px;
}

.pdl10 {
  padding-left: 10px;
}

.pdl15 {
  padding-left: 15px;
}

.pdl20 {
  padding-left: 20px;
}

.pdl30 {
  padding-left: 30px;
}

.pdr3 {
  padding-right: 3px;
}

.pdr5 {
  padding-right: 5px;
}

.pdr7 {
  padding-right: 7px;
}

.pdr10 {
  padding-right: 10px;
}

.pdr15 {
  padding-right: 15px;
}

.pdr20 {
  padding-right: 20px;
}

.pdr30 {
  padding-right: 30px;
}


//暂不使用(未寻找相关业务)

/******************移动端*********************/

/*.mt3 {
  margin-top: 0.3rem;
}
.mt5 {
  margin-top: 0.5rem;
}
.mt7 {
  margin-top: 0.7rem;
}
.mt10 {
  margin-top:1rem;
}
.mt15 {
  margin-top: 1.5rem;
}
.mt20 {
  margin-top: 2.0rem;
}
.mt30 {
  margin-top: 3.0rem;
}
.mt40 {
  margin-top: 4.0rem;
}
.mt50 {
  margin-top: 5.0rem;
}


.mr1 {
  margin-right: 0.1rem;
}
.mr3 {
  margin-right: 0.3rem;
}
.mr5 {
  margin-right: 0.5rem;
}
.mr7 {
  margin-right: 0.7rem;
}
.mr10 {
  margin-right: 1.0rem;
}
.mr15 {
  margin-right: 1.5rem;
}
.mr20 {
  margin-right: 2.0rem;
}
.mr30 {
  margin-right: 3.0rem;
}

.ml1 {
  margin-left: 0.1rem;
}
.ml3 {
  margin-left: 0.3rem;
}
.ml5 {
  margin-left: 0.5rem;
}
.ml7 {
  margin-left: 0.7rem;
}
.ml10 {
  margin-left: 1.0rem;
}
.ml15 {
  margin-left: 1.5rem;
}
.ml20 {
  margin-left: 2.0rem;
}
.ml30 {
  margin-left: 3.0rem;
}

.pd3 {
  padding: 0.3rem;
}
.pd5 {
  padding: 0.5rem;
}
.pd7 {
  padding: 0.7rem;
}
.pd10 {
  padding: 1.0rem;
}
.pd15 {
  padding: 1.5rem;
}
.pd20 {
  padding: 2.0rem;
}
.pd30 {
  padding: 3.0rem;
}

.pdt3 {
  padding-top: 0.3rem;
}
.pdt5 {
  padding-top: 0.5rem;
}
.pdt7 {
  padding-top: 0.7rem;
}
.pdt10 {
  padding-top: 1.0rem;
}
.pdt15 {
  padding-top: 1.5rem;
}
.pdt20 {
  padding-top: 2.0rem;
}
.pdt30 {
  padding-top: 3.0rem;
}

.pdb3 {
  padding-bottom: 0.3rem;
}
.pdb5 {
  padding-bottom: 0.5rem;
}
.pdb7 {
  padding-bottom: 0.7rem;
}
.pdb10 {
  padding-bottom: 1.0rem;
}
.pdb15 {
  padding-bottom: 1.5rem;
}
.pdb20 {
  padding-bottom: 2.0rem;
}
.pdb30 {
  padding-bottom: 3.0rem;
}

.pdl3 {
  padding-left: 0.3rem;
}
.pdl5 {
  padding-left: 0.5rem;
}
.pdl7 {
  padding-left: 0.7rem;
}
.pdl10 {
  padding-left: 1.0rem;
}
.pdl15 {
  padding-left: 1.5rem;
}
.pdl20 {
  padding-left: 2.0rem;
}
.pdl30 {
  padding-left: 3.0rem;
}

.pdr3 {
  padding-right: 0.3rem;
}
.pdr5 {
  padding-right: 0.5rem;
}
.pdr7 {
  padding-right: 0.7rem;
}
.pdr10 {
  padding-right: 1.0rem;
}
.pdr15 {
  padding-right: 1.5rem;
}
.pdr20 {
  padding-right: 2.0rem;
}
.pdr30 {
  padding-right: 3.0rem;
}

.fr10{
  font-size: 1rem;
}
.fr12 {
  font-size: 1.2rem;
}
.fr14 {
  font-size: 1.4rem;
}
.fr16 {
  font-size: 1.6rem;
}
.fr18 {
  font-size: 1.8rem;
}
.fr20 {
  font-size: 2rem;
}
.fr22 {
  font-size: 2.2rem;
}*/
