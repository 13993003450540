
.app {
    background-color: #503E9D;
    color: #fff;
    width: 100%;
    height: 100vh;
    -webkit-font-smoothing:antialiased;
    display:flex;

    .navigation {
        flex: 0 0 300px;
        .home {
            color: #fff;
        }
        .logo {
            margin-top: 84px; 
            margin-left: 62px;
            .iconsxtlogo {
                font-size: 40px;
            }
        }
        .menu {
            margin: 131px 0 0 38px;
            width: 100%;
            .menu-item {
                padding: 16px 24px;
                cursor:pointer;
                .iconfont {
                    margin-right: 10px;
                }
                &.active {
                    background-color: #fff;
                    color: #FB6D3A;
                    font-weight: 600;
                    border-radius: 48px 0 0 48px;
                }
            }
        }
    }

    >article {
        flex: 1;
        position: relative;
        background-color: #503E9D;
        padding: 24px;
        padding-left: 0;
        overflow: hidden;
        .page {
            border-radius: 48px; height: 100%;
            overflow: hidden;
            position: relative;
            .back {
                position: absolute; left: 50px; top: 50px; width: 50px; height: 50px;
                z-index: 10; background-color: #fff;
                text-align: center; line-height: 50px;
                border-radius: 50%; box-shadow: 0 0 3px #aaa;
                .iconfont { 
                    font-size: 14px; color: #333;
                }
            }
        }
    }

    >footer {
        display: flex;
        flex: 0 0 60px;
        font-size: 14px;
        line-height: 60px;
        border-top: 1px solid #333;
        padding: 0 30px;
        justify-content: space-between;
        >.concat {
            display:flex;
            align-items: center;
        }
    }
}

.route-loading {
    width: 100%; height: 100%; display: flex;
    align-items: center; 
    justify-content: center;
}

@media screen and (max-width:768px){

    .app{
        >header{
            text-align: center;
        }
        >footer {
            padding:10px 30px;
            text-align: center;
            display: block;
            line-height:30px;

            >.concat {
                display:block;
            }
        }
    }

}
